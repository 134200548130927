import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFound = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>No encontrado</h1>
    <p>Página no encontrada :(</p>
    <Link to="/">Volver al inicio</Link>
  </Layout>
)

export default NotFound
